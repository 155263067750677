import './App.css';

import Logo_600x100 from "../src/Assets/img/Logo_600x100.png"
function App() {
  return (
    <div className="App">
      <div className='ctn'>
          <img className='logo' src={Logo_600x100} alt="logo" />
          <h2 className='subtitle'>Coming soon</h2>
      </div>
    </div>
  );
}

export default App;
